<template>
  <div>
    <!-- {{ value }} -->
    <b-modal
      id="modal-disable"
      ref="disableModal"
      v-model="toggleModal"
      title="Disable/Enable Confirmation"
      ok-title="Yes"
      :ok-disabled="loading"
      :cancel-disabled="loading"
      :hide-header-close="loading"
      cancel-variant="outline-secondary"
      centered
      no-close-on-backdrop
      no-close-on-esc
      :disabled="loading"
      @ok="handleOk"
      @cancel="handleHide"
      @close="handleHide"
    >
      <span v-html="msg"></span>
      <div class="text-center">
        <b-spinner v-if="loading" label="Text Centered" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      toggleModal: false,
      loading: false
    }
  },
  methods: {
    show() {
      this.toggleModal = true
    },
    handleOk(bvModalEvt) {
      this.$emit('on-submit', bvModalEvt)
    },
    handleHide(bvModalEvt) {
      this.$emit('on-hide', bvModalEvt)
    }
  },
}
</script>
