import axios from 'axios'

function getAll(payload) {
  return axios.get('currency/all', {
    params: payload
  })
}

function updateCurrency(id, data) {
  return axios.put(`currency/${id}`, data)
}

function getCurrencyById(id) {
  return axios.get(`currency/${id}`)
}

function deleteCurrency(id) {
  return axios.delete(`currency/${id}`)
}

function switchCurrencyStatus(id, isWL=null) {
  let wl_code = axios.defaults.headers.wl_code
  if(isWL){
    axios.defaults.headers.wl_code = isWL
  }
  const response = axios.put(`currency/active-deactive/${id}`)
  axios.defaults.headers.wl_code = wl_code
  return response
}

function getCurrencyDropDown() {
  return axios.get('currency')
}
export default {
  getAll,
  updateCurrency,
  getCurrencyById,
  deleteCurrency,
  switchCurrencyStatus,
  getCurrencyDropDown
}
