import store from '@/store'

export default function accessRightCheck(name) {
  let moduleAccess
  //   console.log('getters', store.getters['auth/authenticated'])
  //   store.getters['auth/authenticated'].rights.module.forEach(module => {
  //     if (name === module.name) {
  //       moduleAccess = module
  //     }
  //   })
  store.state.auth.user.rights.module.forEach(module => {
    if (name === module.name) {
      moduleAccess = module
    }
  })
  return moduleAccess
}
