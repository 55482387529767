var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-delete',{ref:"modal",attrs:{"msg":_vm.deleteMsg,"title":_vm.titleMsg},on:{"on-submit":_vm.deleteCurrency}}),_c('confirm-disable',{ref:"disableModal",attrs:{"msg":_vm.disableMsg},on:{"on-submit":_vm.switchCurrencyStatus,"on-hide":_vm.resetStatus}}),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)])],1)],1),(!_vm.contentLoading)?_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(status)",fn:function(data){return [_c('div',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"name":"check-button","switch":""},on:{"change":function($event){return _vm.openDisableModal(
                data.item.id,
                data.item.isActive,
                data.item.code
              )}},model:{value:(data.item.isActive),callback:function ($$v) {_vm.$set(data.item, "isActive", $$v)},expression:"data.item.isActive"}})],1)]}},{key:"cell(actions)",fn:function(data){return [(_vm.checkAccess.Edit || _vm.checkAccess.delete)?_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.Edit
            )?_c('b-dropdown-item',{attrs:{"to":{ name: 'currency-edit', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1):_vm._e()],1):_vm._e()]}}],null,false,117057973)}):_vm._e(),(_vm.contentLoading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.totalCurrency >= 1 ? _vm.startIndex + 1 : _vm.startIndex)+" to "+_vm._s(_vm.endIndex > _vm.totalCurrency ? _vm.totalCurrency : _vm.endIndex)+" of "+_vm._s(_vm.totalCurrency)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"per-page":_vm.perPage,"total-rows":_vm.totalCurrency,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }